<template>
  <div>
    <AppTitle titulo="Esqueci a Senha" />

    <form @submit.prevent @keyup.enter="requestReset">
      <div class="container has-text-centered forgot-password-container">
        <div v-if="!renderMessage">
          <div class="request-content">
            <div class="request-title-subtitle">
              <div class="box-subtitle">
                <h2 class="subtitle is-6">
                  Informe seu e-mail de usuário para solicitar a alteração de senha.
                </h2>
              </div>
            </div>

            <b-field>
              <b-input
                autofocus
                type="email"
                placeholder="Informe seu e-mail"
                v-model="data.email"
                @input="data.email = data.email.toLowerCase()"
              />
            </b-field>

            <a
              :class="[
                'button is-block is-primary is-large save-button',
                loading ? ' is-loading' : '',
              ]"
              @click.prevent.stop="requestReset"
              >Enviar</a
            >
          </div>
        </div>

        <div v-if="renderMessage" class="send-email-message">
          <AppMessage>
            <div slot="message-icon">
              <b-icon icon="check-circle-outline" size="is-large" type="is-success" />
            </div>

            <p slot="message-title">Solicitação de redefinição de senha enviada com sucesso</p>

            <div slot="message">
              Se você inseriu um e-mail cadastrado em nosso sistema, foi enviado o link para a
              redefinição de senha válido por 30 minutos. Caso não visualize na caixa de entrada,
              por favor verifique o lixo eletrônico (Spam).
            </div>
          </AppMessage>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AppMessage from '@/components/AppMessage';
import AppTitle from '@/components/AppTitulo';
import { requestPasswordReset } from '@/resources/users/index';

export default {
  name: 'ForgotPassword',
  components: {
    AppMessage,
    AppTitle,
  },
  data() {
    const data = {
      email: '',
    };
    return {
      data,
      loading: false,
      renderMessage: false,
    };
  },
  methods: {
    async requestReset() {
      if (!this.validateEmail(this.data.email)) {
        this.$alerta('Informe um e-mail válido', 'is-danger', 3000);
        return;
      }

      this.loading = true;
      try {
        await requestPasswordReset({ email: this.data.email });
        this.renderMessage = true;
        this.data.email = '';
      } catch (error) {
        this.$alerta('Erro ao solicitar redefinição de senha', 'is-danger');
      } finally {
        this.loading = false;
      }
    },
    validateEmail(email) {
      if (email.includes('@') && email.includes('.')) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.box-subtitle {
  margin-bottom: 0.5rem;
}

.request-title-subtitle {
  text-align: left;
}

.forgot-password-container {
  align-items: center;
  display: flex;
  height: 65vh;
  justify-content: center;
}

.send-email-message {
  display: flex;
  justify-content: center;
  max-width: 950px;
  padding: 20vh 10vw;
}
</style>
